export const verifyYourEmailAddress = {
  order: 1,
  name: "Verify your email address",
  subcategory: "Manage your account",
  markdown: `# Verify your email address
  To use Tower Hunt's full feature set, including the ability to {% inlineRouterLink %}create new datapoints{% /inlineRouterLink %}, {% inlineRouterLink %}validate datapoints{% /inlineRouterLink %}, and {% inlineRouterLink %}create teams{% /inlineRouterLink %}, you must verify that you control the email address associated with your account:

  {% callout type="caution" marginStyles="my-4" %}
  **Heads up:** You'll know you need to verify your email address if you are signed in and see a black bar across the top of the page. Tower Hunt will also prompt you to verify if you try to use a feature that requires verification.
  {% /callout %}

  {% tablessContainer %}
  1. Check your inbox for an email verification from Tower Hunt. If you don't see it, you can {% inlineRouterLink articleId="resend-email-verification" %}resend it{% /inlineRouterLink %}.
  2. Click **Verify email address**.
  3. Get started using Tower Hunt!
  {% /tablessContainer %}

  {% callout type="info" marginStyles="mt-4" %}
  You can also {% inlineRouterLink articleId="change-your-email-address" %}change your account email address{% /inlineRouterLink %}.
  {% /callout %}

  {% callout type="tip" marginStyles="mt-4" %}
  **Tip:** For help getting started with Tower Hunt, read our {% inlineRouterLink articleId="getting-started-for-new-tower-hunt-users" %}guide for new users{% /inlineRouterLink %}.
  {% /callout %}`,
};
